html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

td,
tbody {
  background: transparent !important;
}

.modal-dialog {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.log-reg {
  background-image: url("../public/images/whitesandbgimg.png");
  background-repeat: no-repeat;
  min-height: 100vh;
}

ul {
  list-style: none;
}

.wrapper {
  min-height: 100vh;
  overflow-x: hidden;
}

.row.logreg-row {
  justify-content: flex-end;
  align-items: center;
  margin-right: 6rem;
}

.navTab {
  padding-top: 4.5rem;
  padding-left: 250px;
  border-bottom: 1px solid #c5c5c5;
  display: none;
}

.navTab .nav-link.active {
  border-bottom: 2px solid #4fadea;
  /* display: none; */
}
.logreg-form {
  background: #fff;

  width: 70%;
}
.logreg-form .form-control {
  border-radius: 1rem;
}
.-image {
  text-align: center;
}

.logreg-form form .row {
  margin-bottom: -7%;
}
.logreg-form h3 {
  margin-bottom: 8%;
  margin-top: -10%;
  text-align: center;
  color: #0062cc;
}
.logreg-form .btnLogin {
  width: 100%;
  border: none;
  border-radius: 1rem;
  padding: 1.5%;
  background: #4fadea;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.btnLoginSubmit {
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  color: #fff;
  background-color: #0062cc;
  border: none;
  cursor: pointer;
}

.inputElement {
  border: 0.5px solid #c4c4c4;
  border-top-left-radius: 1.2rem !important;
  border-bottom-left-radius: 1.2rem !important;
  border-top-right-radius: 1.2rem !important;
  border-bottom-right-radius: 1.2rem !important;
  background-color: #dfe6ec;
}

select.inputElement {
  background-color: #ffffff;
}

.loginCtaTitle {
  position: relative;
}

.loginCtaTitle::after {
  content: "";
  position: absolute;
  display: block;
  height: 2px;
  width: 14rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: #002855;
  border-radius: 15px;
}

.welcomeTxt {
  font-weight: 350;
}

.userHomeTopNav {
  height: 4.5rem;
  padding: 1rem 1.5rem;
  border-bottom: 2px solid #c4c4c4;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
}

.userHomeTopNav button {
  background-color: transparent;
  border: none;
}

.userHomeSideNav {
  width: 250px;
  position: fixed;
  border-right: 2px solid #e5e5e5;
  min-height: 100vh;
  margin-top: 4.5rem;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #4fadea;
  z-index: 3;
}

.sideNavInner ul li {
  padding: 10px 0px;
}

.sideNavInner ul li.active {
  border: 0.5px solid #e5e5e5;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sideNavInner ul li a .optionIcon {
  width: 40px;
  height: 40px;
}

.sideNavInner ul li a.dashboard .optionIcon {
  border-radius: 50%;
  background-color: #9fce63;
}

.mainContent {
  padding-top: 4.5rem;
  position: relative;
  padding-left: 250px;
  z-index: 2;
  min-height: 100vh;
  position: relative;
  overflow-y: auto;
  margin-left: 0;
}

.customCard {
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  background-color: #f8fbfd;
  min-height: 10rem;
  padding: 10px 25px;
  width: 100%;
}

.mainContent .customCard.userHomeStatus .title {
  font-size: 14px;
  color: #002855;
  font-weight: 500;
  line-height: 16.41px;
}

.mainContent .customCard.userHomeStatus .status {
  font-size: 30px;
  color: #000;
  font-weight: 500;
  line-height: 37.41px;
}

.btnNext {
  background: #002855;
  border-radius: 94px;
  color: #fff;
  padding: 10px 60px;
}

.btnNext.small {
  background: #002855;
  border-radius: 94px;
  color: #fff;
  padding: 5px 30px;
}

.btnPrev {
  background: #fff;
  border-radius: 94px;
  color: black;
  margin-right: 5px;
  padding: 10px 60px;
}

.table {
  width: 100%;
  margin: 0;
  font-size: 13px;
  line-height: 1.5rem;
}

.table-responsive {
  display: block;
  overflow-x: scroll !important;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.table-responsive::-webkit-scrollbar {
  width: 0.5rem; /* Adjust the width as needed */
}

@media screen and (max-width: 900px) {
  .row.logreg-row {
    justify-content: center;
    margin-right: 0rem;
  }
}

@media screen and (max-width: 768px) {
  .userHomeSideNav {
    display: none !important;
  }

  .mainContent {
    padding-left: 0px;
    padding-top: 0px;
  }

  .navTab {
    display: block;
    padding-left: 0px;
  }

  .btnNext,
  .btnPrev {
    padding: 10px 40px;
  }
}

/*

Custom Select Component Styles Start

*/

.customSelectDropdown {
  position: relative;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #d9d9d9;
}

.selectHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  overflow: hidden;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.option {
  padding: 8px 10px;
}

/*

Custom Select Component Styles End

*/

.customCard.userTable {
  min-height: 60vh;
}

.userSearchInput {
  padding: 5px 40px;
  border-radius: 38px;
  border: 1px solid #b6b6b6;
}

.userSearchButton {
  padding: 7px 35px;
  border-radius: 38px;
  border: 1px solid #b6b6b6;
  background-color: #9fce63;
  font-size: small;
  color: #fff;
}

.userSearchIcon {
  transform: translate(15px, 4.5px);
}

.userInfoModal .modal-dialog {
  min-width: 45rem;
}

.userInfoModal .navInfoTabs .btn {
  border-radius: 94px;
  background: #d9d9d9;
  padding: 6px 15px;
  font-size: 8px;
  font-weight: 600;
}

.userInfoModal .navInfoTabs .btn.active {
  background-color: #4fadea;
  color: #fff;
}

.motherDetailsTab h5,
.motherDetailsTab p,
.fatherDetailsTab h5,
.fatherDetailsTab p,
.personalDetailsTab h5,
.personalDetailsTab p {
  font-size: 10px;
}

.dropdown-toggle.user::after {
  display: none !important;
}

.dropdown-menu {
  z-index: 100 !important;
}

/* .notification-modal {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  border-radius: 4px;
  animation: slide-down 0.5s ease;
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
} */

.notifcationModal {
  position: absolute;
  width: 300px;
  height: 45px;
  border: 1px solid #3fc59d;
  border-radius: 5px;
  top: 5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  right: 50px;
  animation: slide-down 0.8s ease;
}

.notifcationModal .notificationModalMessage {
  font-size: 12px;
}

@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.dateContainer > div {
  display: flex;
  justify-content: center;
}

.react-datepicker__month-container {
  width: 350px;
}

.react-datepicker__day--selected {
  background-color: #0047ff !important;
  color: #fff !important;
}

.react-datepicker__day {
  margin: 0.166rem 0.58rem !important;
}

.react-datepicker__day-name {
  margin: 0.166rem 0.58rem !important;
}

/* Make the selected dates blue */
.react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}

.react-datepicker__month-container {
  border-radius: 16px;
  background: var(--light-mode-secondary-light-gray-96, #f5f5f5);
  box-shadow: 0px 1px 2px 0px rgba(0, 14, 51, 0.25);
}

.react-datepicker__navigation {
  border-radius: 80px;
  background: #fff !important;
  box-shadow: 0px 1px 1px 0px rgba(0, 14, 51, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__navigation-icon--previous::before {
  top: 12px !important;
  right: -4px;
}

.react-datepicker__navigation-icon--next {
  top: 4px !important;
  left: 2px !important;
}

.react-datepicker__current-month {
  width: 50% !important;
  margin: 0 auto !important;
  background-color: #fff;
  border-radius: 6px;
  padding: 10px 0px;
}

.react-datepicker__navigation {
  top: 16px !important;
}

.react-datepicker__day {
  background-color: #fff !important;
  padding: 4.5px 7px !important;
  text-align: center !important;
}

.react-datepicker__day.selected-date {
  background-color: #007bff !important;
}

.react-datepicker__day--disabled {
  background-color: #f5f5f5 !important;
}

/* Apply a blue background to the selected dates */
.selected-date {
  background-color: #007bff;
  color: #fff;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px;
}

.date-label {
  display: block;
  padding: 8px;
  cursor: pointer;
}

.date-label input[type="radio"] {
  margin-right: 8px;
}

.date-label:hover {
  background-color: #f0f0f0;
  border-radius: 6px;
}

.date-label input[type="radio"]:checked + span {
  background-color: #007bff !important;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
}

/* Custom CSS for InterviewDates component */
.interview-date-item {
  margin-bottom: 12px !important; /* Add spacing between each radio button and label */
}

/* Define custom styles for disabled input elements */
input:disabled {
  /* Your custom styles here */
  background-color: #f2f2f2; /* Change the background color */
  color: #999; /* Change the text color */
  border: 1px solid #ccc; /* Change the border color */
  /* Add any other styles you want */
}

.applicant-phone::-webkit-inner-spin-button,
.applicant-phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Loading component styles */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
}

.loading-spinner {
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
